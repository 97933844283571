import {FormProvider, useForm} from 'react-hook-form';
import {
  Grid,
  AccordionSummary,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  TablePagination,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import round from 'utils/roundIfNecessary';
import useGenerateRecommendedOffers
  from 'api/hooks/useGenerateRecommendedOffers';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  setOpenToast,
  setSeverityToast,
  setMessageToast,
  setUpdateRecommendationLayerFromWidget,
  setSelectWidgetId,
  setWidgetFiltersForTargetDemand,
  setWidgetFiltersForCurrentOffer,
  setRecomputedRecommendationsWidgets
} from 'store/appSlice';
import {GLOBAL, messageSeverity} from 'utils/constants';
import {useTranslation} from 'components/providers/TranslationProvider';
import apiPaths from 'api/apiPaths';
import {status200} from 'api/status.utils.js';
import { GridActionsCellItem } from '@mui/x-data-grid';
import axios from 'api/axios/axiosInstance.js';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';
import VariableInfo from 'components/common/VariableInfo';
import GenerateOrComputeRecommendations
  from './modals/GenerateOrComputeRecommendations'
import {processJobStatus} from 'utils/constants';
import FilterRecommendationsPopover from './FilterRecommendationsPopover';
import IndicatorOfFiltersAppliedOnAlayer
  from './IndicatorOfFiltersAppliedOnAlayer';
import {getWidgetsWithFiltersByLayerId} from 'utils/supportComponents';
import Loader from "../../../views/organizations/common/Loader";
import {
  filterOverDemandContainerStyle,
  filterOverDemandContainerWithoutStyle,
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionHeader,
  MuiBoxIcon,
  MuiCheckbox,
  MuiCreateIcon,
  MuiDivAccordion,
  MuiDivHeader,
  MuiDivider,
  MuiDivRoot,
  MuiIconButton,
  MuiOpenInFullIcon,
  MuiPinDropIcon,
  MuiRecomendationsVariables,
  MuiRecommendationsCandidatesHeader,
  MuiRecommendationsCandidatesLabel,
  MuiRecommendationsTableHeaderContainer,
  MuiRefreshIcon,
  MuiTableCell,
  MuiTableCellIcon,
  MuiTableCellItem,
  MuiTableHead,
  MuiThumbUpIcon,
  MuiVisibilityIcon,
  MuiWidgetSubHeader,
  muiHeaderDivider, MuiIconButtonHover
} from './styles/recommendationsWidgetUI';

function RecommendationsWidgetUI(props) {
  const {
    data,
    onSelect,
    id,
    customData,
    updateListForDelete,
    demandDatasetColumns,
    demandDatasetColumnsIsSuccess,
    targetDemandFilter,
    currentOfferFilter,
    dynamicRecommendationsName,
    processJobStatusPotOffers,
    handleFilterRecommendations,
    setProcessJobName,
    offerTypesFilter,
    setRecommendationsComputionData,
    sortedTypeByColumn,
    sortedTypeById,
    columnsToOrder,
    runFilterHandler,
  } = props;
  const {columns: dataCols = [], values: dataRows = []} = data || {};
  const [viewSelected, setViewSelected] = React.useState(null);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const location = useLocation();
  const splittedPathname = React.useMemo(() => location.pathname.split('/'), [location.pathname]);
  const dispatch = useDispatch();
  const {t, lng} = useTranslation();
  const [, setShowProgressBar] = React.useState(false);
  const [privateLoading, setPrivateLoading] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const [headerMessage, setHeaderMessage] = React.useState('');
  const [titleMessage, setTitleMessage] = React.useState('');
  const [progress, setProgress] = React.useState(0);
  const recommendationsGeneratedSuccessfully = React.useRef(false);
  const parametersCatalog = useSelector((state) => state.app.parametersCatalog);
  const [, setSeverity] = React.useState('error');
  const [, setIsOfferCapacitySelectedLink] = React.useState(false);

  const filterRecommendations = useSelector((state) => state.app.listRecommendationsWidgetForDelete);
  const widget = useSelector((state) => state.app.widgets.find(w => w.id === id));
  const recomputedRecommendationsWidgets = useSelector((state) => state.app.recomputedRecommendationsWidgets);
  const selectWidgetId = useSelector((state) => state.app.selectWidgetId);

  const selectedWidgetId = useSelector((state) => state.app.selectWidgetId);
  const selectedWidgetEntity = useSelector((state) => (state.app.recomputedRecommendationsWidgets.find(w => w.id === selectedWidgetId) || state.app.widgets.find(w => w.id === selectedWidgetId)));
  const sources = useSelector((state) => state.carto.dataSources);
  const layers = useSelector((state) => state.app.layers);
  const widgets = useSelector((state) => state.app.widgets);
  const spatialFilter = useSelector((state) => state.app.listFilterPolygonData);

  const [dataForRecom, setDataForRecom] = React.useState({});
  const [dataForOP, setDataForOP] = React.useState({});

  const [updatedWidget, setUpdatedWidget] = React.useState({...widget.params});

  const {
    mutateAsync: generateRecommendedOffers,
    data: recommendedOffersData,
    status: recommendedOffersDataStatus,
    isSuccess: generateRecommendedOffersIsSuccess,
    isLoading: generateRecommendedOffersIsLoading,
  } = useGenerateRecommendedOffers();

  const getLanguage = () => {
    switch (lng) {
      case 'EN':
        return 'english';
      case 'FR':
        return 'french';
      case 'PO':
        return 'portuguese';
      case 'ES':
        return 'spanish';
      default:
        return 'english';
    }
  }
  // COMPONENTE TABLA

  const [openRow, setOpenRow] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 7));
    setPage(0);
  }

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index); // Alternar entre abrir y cerrar la fila
  };

  const schema = yup.object().shape({
    budget: yup.number(),
    expanxionCapacity: yup.number(),
    expanxionCost: yup.number(),
    densityVariable: yup.string(),
    welfareVariable: yup.string(),
  });

  const getDefaultValues = () => {
    return {
      budget: customData.budget ? customData.budget : 1,
      expanxionCapacity: customData.recommended_expansion ? customData.expansion_capacity : 500,
      expanxionCost: customData.recommended_expansion ? customData.expansion_cost : 500,
      welfareVariable: customData.welfareVariable,
      densityVariable: customData.densityVariable,
      offerCoverageRadius: customData.coverage ? customData.coverage : customData.offerCoverageRadius,
      offerCost: customData.cost_lower ? customData.cost_lower : customData.offerCost,
      isOfferCapacitySelected: customData.capacity_lower ? true : customData.isOfferCapacitySelected,
      offerCapacity: customData.capacity_lower ? customData.capacity_lower : customData.offerCapacity
    }
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const { watch } = methods;

  const rows = dataRows.map((d, index) => {
    Object.entries(d).map(([k, v]) => {
      if (k === 'geo_isochrone') {
        d[k] = JSON.parse(v);
      }
      if (typeof v === 'boolean') {
        return d[k] = v;
      }
      if (typeof v !== 'object' && k !== 'cartodb_id') {
        return (d[k] = round(v, 3));
      }
      return (d[k] = v);
    });
    return {
      id: index,
      ...d,
    };
  });

  const wasComputedWithFilters = useMemo(
    () => {
      if (widget.params.isGlobal) {
        return !!(widget?.custom?.widgetFiltersForTargetDemand?.length > 0 || widget?.custom?.widgetFiltersForCurrentOffer?.length > 0)
      }
      return !!(widget?.params?.widgetFiltersForTargetDemand?.length > 0 || widget?.params?.widgetFiltersForCurrentOffer?.length > 0)
    },
    [widget]
  );

  const wasComputedWithFiltersForTargetDemand = useMemo(
    () => {
      if (widget.params.isGlobal) {
        return widget?.custom?.widgetFiltersForTargetDemand?.length > 0
      }
      return widget?.params?.widgetFiltersForTargetDemand?.length > 0
    },
    [widget]
  );

  const wasComputedWithFiltersForCurrentOffer = useMemo(
    () => {
      if (widget.params.isGlobal) {
        return widget?.custom?.widgetFiltersForCurrentOffer?.length > 0
      }
      return widget?.params?.widgetFiltersForCurrentOffer?.length > 0
    },
    [widget]
  );

  const isOfferCapacitySelected = watch('isOfferCapacitySelected');
  const offerCapacity = watch('offerCapacity');
  const offerCoverageRadius = watch('offerCoverageRadius')
  const offerCost = watch('offerCost');

  const handleGenerateRecommendations = async (budget, densityVariable, recommendedExpansion, expanxionCost, expanxionCapacity, isWelfareFormulaSelected, welfareFormula, welfareVariable, coverageType) => {
    const scheme_id = location.pathname.split('/')[6];
    let localParametersCatalog = null;
    if (parametersCatalog?.length > 0) {
      localParametersCatalog = {};
      parametersCatalog.forEach(p => {
        localParametersCatalog[p.propName] = p.value
      });
    }
    const payload = {
      "payload": {
        "demand_dataset_name": selectedWidgetEntity.params.demandDatasetName,
        "quant_var": densityVariable,
        "budget": budget,
        "user_name": dynamicRecommendationsName,
        "scenario_id": selectWidgetId,
        "recommend_expansion": recommendedExpansion,
        "max_expansion_cost": expanxionCost,
        "max_expansion_capacity": expanxionCapacity,
        "notification_host": process.env.REACT_APP_PROCESS_JOB_ENDPOINT + apiPaths.processJob,
        "parametersCatalog": localParametersCatalog,
        "lang": getLanguage(),
        "distance_metric": coverageType ? 'EUCLIDEAN': 'ISO_POLYGON'
      }
    };

    if (targetDemandFilter) {
      payload.payload['demand_custom_filter'] = targetDemandFilter;
    }

    if (currentOfferFilter) {
      payload.payload['current_offer_filter'] = currentOfferFilter;
    }

    if (isWelfareFormulaSelected) {
      payload.payload.custom_w_var = welfareFormula;
    } else {
      payload.payload.w_var = welfareVariable;
    }
    if (Object.keys(spatialFilter).length > 0){
      const key =Object.keys(spatialFilter)[0]
      payload.payload['spatial_filter_table'] = `incluia-project.incluia_datasets.widget_polygon_`+ spatialFilter[key].mapId + '_' + spatialFilter[key].widgetId;
      payload.payload['spatial_filter_column'] = 'polygon_id';
      payload.payload['spatial_feature_ids'] = spatialFilter[key].polygons;
    }

    generateRecommendedOffers(payload).then((response) => {
      performActionsAfterUpload(t('recommended_offers_generating'));
      if (response.value) {
        const processJobId = response.value.processJobId;
        let intervalMaxFailedRequest = 10;
        let intervalFailedRequestCount = 0;
        let intervalHandler = setInterval(() => {
          if (intervalFailedRequestCount === intervalMaxFailedRequest) {
            setShowProgressBar(false);
            setPrivateLoading(false);
            clearInterval(intervalHandler);

            const messageToastParams = {
              status: true,
              info: t('recommended_offers_error_occurred'),
              severity: messageSeverity.ERROR
            };
            manageMessageToast(messageToastParams);
          }
          axios.get(`${apiPaths.processJob}/${processJobId}`, status200).then(processJobResponse => {
            if (processJobResponse && processJobResponse.status === 200 && processJobResponse.data) {
              if (processJobResponse.data.value) {
                const processJob = processJobResponse.data.value;
                setHeaderMessage(t('recommended_offers_generating'));
                setTitleMessage(t('recommended_offers_generating'));
                if (processJob.status === 'in_progress') {
                  setProgress(processJob.progress);
                  setNotificationMessage(processJob.message);
                  customData.setWidgetProcessJobStatus(processJobStatus.INPROGRESS);
                }
                if (processJob.status === messageSeverity.SUCCESS) {
                  setShowProgressBar(false);
                  recommendationsGeneratedSuccessfully.current = true
                  setPrivateLoading(false);
                  clearInterval(intervalHandler);
                  customData.refetchRecommendationsOffers();
                  customData.refetchListDataIsochrone();
                  dispatch(setSelectWidgetId(id));
                  dispatch(setUpdateRecommendationLayerFromWidget(true));
                  customData.setWidgetProcessJobStatus(processJobStatus.SUCCESS);
                  setProgress(0);
                  setTitleMessage('');
                  setHeaderMessage('');
                }
                if (processJob.status === 'failed') {
                  setShowProgressBar(false);
                  setPrivateLoading(false);
                  clearInterval(intervalHandler);
                  const messageToastParams = {
                    status: true,
                    info: `${t('recommended_offers_error_occurred')}: ${processJob.message}`,
                    severity: messageSeverity.ERROR
                  };
                  manageMessageToast(messageToastParams)
                  customData.setWidgetProcessJobStatus(processJobStatus.FAILED);
                }
              } else {
                setShowProgressBar(false);
                setPrivateLoading(false);
                clearInterval(intervalHandler);
                const messageToastParams = {
                  status: true,
                  info: `${t('recommended_offers_error_occurred')}: ${processJob.message}`,
                  severity: messageSeverity.ERROR
                };
                manageMessageToast(messageToastParams);
                customData.setWidgetProcessJobStatus(processJobStatus.FAILED);
              }
            } else {
              intervalFailedRequestCount++;
            }
          }).catch(err => {
            setShowProgressBar(false);
            setPrivateLoading(false);
            clearInterval(intervalHandler);
            const messageToastParams = {
              status: true,
              info: t('recommended_offers_error_occurred'),
              severity: messageSeverity.ERROR
            };
            manageMessageToast(messageToastParams)
            customData.setWidgetProcessJobStatus(processJobStatus.FAILED);
          });
        }, 1600);

        const compute = {
          budget: payload.payload.budget,
          custom_w_var: payload.payload.custom_w_var,
          expansion_cost: payload.payload.max_expansion_cost,
          expansion_capacity: payload.payload.max_expansion_capacity,
          recommended_expansion: payload.payload.recommend_expansion,
          quant_var: payload.payload.quant_var,
          w_var: payload.payload.w_var,
          is_offer_capacity_selected: selectedWidgetEntity.isOfferCapacitySelected,
          is_welfare_formula_selected: selectedWidgetEntity.is_welfare_formula_selected,
          coverage_type: coverageType
        }

        setRecommendationsComputionData({...compute});
      }
    });
  };

  const showMessage = (message, severity = messageSeverity.SUCCESS) => {
    setSeverity(severity);
    setOpenToast(true);
    setMessageToast(message);
  };

  const performActionsAfterUpload = (message) => {
    setPrivateLoading(true);
    setShowProgressBar(true);
  };

  const manageMessageToast = (message) => {
    dispatch(setOpenToast(message.status));
    dispatch(setMessageToast(message.info));
    dispatch(setSeverityToast(message.severity))
  }

  const handleDataCheckboxChange = (event, rowIndex) => {
    const selectedIndex = selectionModel.indexOf(rowIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectionModel, rowIndex);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectionModel.slice(1));
    } else if (selectedIndex === selectionModel.length - 1) {
      newSelected = newSelected.concat(selectionModel.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectionModel.slice(0, selectedIndex),
        selectionModel.slice(selectedIndex + 1)
      );
    }

    setSelectionModel(newSelected);
  }

  React.useEffect(() => {
    setIsOfferCapacitySelectedLink(isOfferCapacitySelected)
  }, [isOfferCapacitySelected])

  React.useEffect(() => {
    updateListForDelete(selectionModel)

    if (selectionModel.length === 0) {
      setSelectAllChecked(false);
    }
  }, [selectionModel.length])

  React.useEffect(() => {
    setDataForRecom({
      ...dataForRecom,
      budget: customData.budget ? customData.budget : 1,
      expanxionCapacity: customData.recommended_expansion ? customData.expansion_capacity : 500,
      expanxionCost: customData.recommended_expansion ? customData.expansion_cost : 500,
      welfareVariable: customData.w_var,
      densityVariable: customData.quant_var,
      isWelfareFormulaSelected: !!customData.custom_w_var,
      welfareFormula: customData.custom_w_var,
      demandDatasetColumnsIsSuccess,
      demandDatasetColumns,
      handleGenerateRecommendations,
      widget,
      customData,
    })
    setDataForOP({
      ...dataForOP,
      isOfferCapacitySelected: customData.capacity_lower ? true : customData.isOfferCapacitySelected,
      offerCapacity: customData.capacity_lower ? customData.capacity_lower : customData.offerCapacity,
      offerCoverageRadius: customData.coverage ? customData.coverage : customData.offerCoverageRadius,
      offerCost: customData.cost_lower ? customData.cost_lower : customData.offerCost,
      performActionsAfterUpload,
      setShowProgressBar,
      setPrivateLoading,
      manageMessageToast,
      setProgress,
      setNotificationMessage,
      messageSeverity,
      recommendationsGeneratedSuccessfully,
      showMessage,
      setHeaderMessage,
      setTitleMessage
    })
    setIsOfferCapacitySelectedLink(isOfferCapacitySelected)
  }, [demandDatasetColumnsIsSuccess, demandDatasetColumns, widget, customData])

  React.useEffect(() => {
    if (Object.keys(filterRecommendations).length > 0 && selectionModel.length == 0) {
      if (filterRecommendations[props.id]) {
        const selectedIndex = filterRecommendations[props.id].map(objSeleccionado => {
          return dataRows.findIndex(item => item.ido === objSeleccionado.recommendation_id);
        });
        setSelectionModel(selectedIndex);
      } else {
        setSelectionModel([]);
      }
    } else if (Object.keys(filterRecommendations).length == 0) {
      setSelectionModel([]);
    }
  }, [filterRecommendations]);

  React.useEffect(() => {
    const regeneratedRecommendationWidget = recomputedRecommendationsWidgets.find((item) => item.id === selectWidgetId)
    if (widget.id === selectWidgetId && regeneratedRecommendationWidget) {
      setUpdatedWidget(regeneratedRecommendationWidget);
    } else {
      setUpdatedWidget(widget);
    }
  }, [recomputedRecommendationsWidgets])

  const parametersCandidateRecommendations = {
    isOfferCapacitySelected,
    offerCapacity,
    offerCoverageRadius,
    offerCost,
    performActionsAfterUpload,
    setPrivateLoading,
    manageMessageToast,
    setProgress,
    setNotificationMessage,
    messageSeverity,
    setShowProgressBar,
    recommendationsGeneratedSuccessfully,
    showMessage,
    setHeaderMessage,
    setTitleMessage
  }

  const showInfoIcon = (item) => {
    if (!item.fixed && item.expansion_recommended) {
      return <MuiBoxIcon>
              <MuiOpenInFullIcon/>
             </MuiBoxIcon>
    }
    if (!item.fixed) {
      return <MuiBoxIcon>
                <MuiThumbUpIcon />
             </MuiBoxIcon>;
    }
    if (item.fixed) {
      return <MuiBoxIcon>
               <MuiPinDropIcon/>
             </MuiBoxIcon>
    }
  }

  const handleSelectAllCheckboxChange = (event) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    const selectedIndexes = checked ? rows.map((row, index) => index) : [];
    setSelectionModel(selectedIndexes);
  };

  const handleOpenComputePOModal = () => {
    dispatch(setSelectWidgetId(id));
    dispatch(setWidgetFiltersForTargetDemand([]));
    navigate(`${location.pathname}?recommendations=compute`, {
      replace: true,
    });
  }

  const handleOpenGenerateRecommendationsModal = () => {
    dispatch(setSelectWidgetId(id));
    dispatch(setWidgetFiltersForCurrentOffer([]));
    navigate(`${location.pathname}?recommendations=generate`, {
      replace: true,
    });
  }

  const handleReGenerateRecommendations = () => {
    const payloadToGenerateRecommendations = _.cloneDeep(updatedWidget);
    payloadToGenerateRecommendations.params.budget = updatedWidget.params.budget || 1;
    payloadToGenerateRecommendations.params.expansion_capacity = updatedWidget.params.expansion_capacity || updatedWidget.params.expanxionCapacity;
    payloadToGenerateRecommendations.params.expansion_cost = updatedWidget.params.expansion_cost || updatedWidget.params.expanxionCost;
    payloadToGenerateRecommendations.params.w_var = updatedWidget.params.w_var || updatedWidget.params.welfareVariable;
    payloadToGenerateRecommendations.params.quant_var = updatedWidget.params.quant_var || updatedWidget.params.densityVariable;
    payloadToGenerateRecommendations.params.custom_w_var = updatedWidget.params.custom_w_var || updatedWidget.params.welfareFormula;
    payloadToGenerateRecommendations.recommended_expansion = updatedWidget.params.recommended_expansion || updatedWidget.params.recommendedExpansion
    dispatch(setRecomputedRecommendationsWidgets(payloadToGenerateRecommendations));
    setProcessJobName('generate');
    dispatch(setSelectWidgetId(id));

    handleGenerateRecommendations(
      payloadToGenerateRecommendations.params.budget,
      payloadToGenerateRecommendations.params.quant_var,
      payloadToGenerateRecommendations.recommended_expansion,
      payloadToGenerateRecommendations.params.expansion_cost,
      payloadToGenerateRecommendations.params.expansion_capacity,
      payloadToGenerateRecommendations.params.isWelfareFormulaSelected
    );

    dispatch(setRecomputedRecommendationsWidgets(payloadToGenerateRecommendations));

    const widgetFiltersForCurrentOfferResp = getWidgetsWithFiltersByLayerId(updatedWidget.params.currentOfferLayerName, sources, layers, widgets);
    const widgetFiltersForTargetDemandResp = getWidgetsWithFiltersByLayerId(selectedWidgetEntity.layer, sources, layers, widgets);
    dispatch(setWidgetFiltersForTargetDemand(
      widgetFiltersForTargetDemandResp.map(obj => ({...obj}))
    ));
    dispatch(setWidgetFiltersForCurrentOffer(
      widgetFiltersForCurrentOfferResp.map(obj => ({...obj}))
    ));
  }

  const widgetFiltersNumberForTargetDemand = useMemo(
    () => {
      return getWidgetsWithFiltersByLayerId(selectedWidgetEntity?.layer, sources, layers, widgets).length;
    },
    [selectedWidgetEntity?.layer, sources, layers, widgets]
  );

  const widgetFiltersNumberForCurrentOffer = useMemo(
    () => {
      return getWidgetsWithFiltersByLayerId(updatedWidget?.params?.currentOfferLayerName, sources, layers, widgets).length;
    },
    [updatedWidget?.params?.currentOfferLayerName, sources, layers, widgets]
  );

  return (
    <Grid item xs={12}>
      <Loader
        loaderType={'progress'}
        progress={progress}
        loading={privateLoading}
        polygonDataLoading={privateLoading}
        notificationMessage={notificationMessage}
        headerMessage={headerMessage}
        titleMessage={titleMessage}
      />
      <MuiDivRoot>
        <FormProvider {...methods}>
          <form>
            <Grid container style={{marginTop: 10}}>
              <MuiAccordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <MuiAccordionHeader> {t('current_config')} </MuiAccordionHeader>
                </AccordionSummary>
                <MuiAccordionDetails>
                  <Grid container direction='column'>
                    <Grid item xs={12}>
                      <MuiRecommendationsCandidatesHeader>
                        <MuiRecommendationsCandidatesLabel>
                          {t('service_point_config')}
                        </MuiRecommendationsCandidatesLabel>
                        <MuiIconButtonHover
                          onClick={handleOpenComputePOModal}
                          disabled={!widget.params.allowsRegenerateServicePoint}
                        >
                          <MuiCreateIcon/>
                        </MuiIconButtonHover>
                      </MuiRecommendationsCandidatesHeader>
                      <div>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <VariableInfo
                              label={widget.params?.customLabels?.coverageRadius || t('coverage_radius')}
                              filterData={(updatedWidget?.params?.coverage || updatedWidget?.params?.coverage === 0) ? updatedWidget?.params?.coverage : updatedWidget.params?.offerCoverageRadius}
                              name="(coverage)"
                              information="recommendations_offer_coverage_radius"/>
                          </Grid>
                          <Grid item xs={6}>
                            <VariableInfo
                              label={widget.params?.customLabels?.cost || t('cost')}
                              filterData={(updatedWidget?.params?.offer_cost || updatedWidget?.params?.offer_cost === 0) ? updatedWidget?.params?.offer_cost : updatedWidget?.params?.offerCost}
                              name={`(${t('unit').toUpperCase()})`}
                              information="recommendations_offer_cost"/>
                          </Grid>
                          <Grid item xs={6}>
                            <VariableInfo
                              label={widget.params?.customLabels?.recommendationsOfferCapacity || t('recommendations_offer_capacity')}
                              filterData={(updatedWidget?.params?.offer_capacity || updatedWidget?.params?.offer_capacity === 0) ? updatedWidget?.params?.offer_capacity : updatedWidget?.params?.offerCapacity}
                              name="(capacity_lower_bound)"
                              information="recommendations_offercapacity_tooltip"/>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  {/* second element */}
                  <MuiDivider/>
                  <Grid container direction='column'>
                    <Grid item xs={12}>
                      <MuiRecommendationsCandidatesHeader>
                        <MuiRecommendationsCandidatesLabel>
                          {t('recommendations_computations_header')}
                        </MuiRecommendationsCandidatesLabel>
                        <Box>
                          {((widgetFiltersNumberForTargetDemand > 0 || widgetFiltersNumberForCurrentOffer > 0) &&
                            <MuiIconButtonHover
                              onClick={handleReGenerateRecommendations}>
                              <MuiRefreshIcon/>
                            </MuiIconButtonHover>)}

                          <MuiIconButtonHover
                            onClick={handleOpenGenerateRecommendationsModal}>
                            <MuiCreateIcon/>
                          </MuiIconButtonHover>
                        </Box>
                      </MuiRecommendationsCandidatesHeader>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <VariableInfo
                              label={widget.params?.customLabels?.recommendationsBudget || t('budget')}
                              filterData={updatedWidget?.params?.budget}
                              name={`(${t('unit').toUpperCase()})`}
                              information="recommendations_budget_info"/>
                          </Grid>
                          <Grid item xs={6}>
                            <VariableInfo
                              label={widget.params?.customLabels?.recommendationsExpansionCapacity || t('recommendations_expansion_capacity_box')}
                              filterData={updatedWidget?.params?.expansion_capacity ? updatedWidget?.params?.expansion_capacity : updatedWidget?.params?.expanxionCapacity}
                              name="(expanded_capacity)"
                              information="recommendations_expansion_capacity_info"/>
                          </Grid>
                          <Grid item xs={6}>
                            <VariableInfo
                              label={widget.params?.customLabels?.recommendationsExpansionCost || t('recommendations_expansion_cost')}
                              filterData={updatedWidget?.params?.expansion_cost ? updatedWidget?.params?.expansion_cost : updatedWidget?.expanxionCost}
                              name={`(${t('unit').toUpperCase()})`}
                              information="recommendations_expansion_cost_info"/>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  {(wasComputedWithFilters && <MuiDivider/>)}
                  {(wasComputedWithFilters &&
                    <Grid container direction='column'>
                      {(wasComputedWithFiltersForCurrentOffer &&
                        <Grid item xs={12}>
                          <Box>
                            <IndicatorOfFiltersAppliedOnAlayer
                              header={t('filter_over_service_point')}
                              widgetsFilteringTargetDemmand={widget.params.isGlobal ? widget.custom.widgetFiltersForCurrentOffer || [] : widget.params.widgetFiltersForCurrentOffer || []}
                            />
                          </Box>
                        </Grid>)}
                      {(wasComputedWithFiltersForTargetDemand &&
                        <Grid item xs={12}>
                          <Box>
                            <IndicatorOfFiltersAppliedOnAlayer
                              header={t('filter_on_demand')}
                              widgetsFilteringTargetDemmand={widget.params.isGlobal ? widget.custom.widgetFiltersForTargetDemand || [] : widget.params.widgetFiltersForTargetDemand || []}
                            />
                          </Box>
                        </Grid>)}
                    </Grid>)}
                  <GenerateOrComputeRecommendations
                    defaultValues={customData}
                    dataForOP={dataForOP}
                    dataForRecom={dataForRecom}
                    widget={widget}
                    demandDatasetColumnsIsSuccess={demandDatasetColumnsIsSuccess}
                    setDataForRecom={setDataForRecom}
                    setDataForOP={setDataForOP}
                    widgetId={id}
                    setProcessJobName={setProcessJobName}
                  />
                </MuiAccordionDetails>
              </MuiAccordion>
              <MuiDivAccordion>
                <MuiRecommendationsTableHeaderContainer container >
                  <Grid item xs={12} sm={12}>
                    <Box height={52} display="flex" alignItems={"center"}
                         justifyContent={"space-between"}>
                      <MuiWidgetSubHeader>
                        {t('recommendations_widget_title')}
                      </MuiWidgetSubHeader>
                      <>
                        {(
                          selectionModel.length !== 0 ?
                            <MuiIconButton
                              onClick={handleFilterRecommendations}
                              disabled={selectionModel.length === 0}
                            >
                              <FilterListIcon/>
                            </MuiIconButton>
                            :
                            <FilterRecommendationsPopover
                              offerTypes={offerTypesFilter.offerTypes}
                              setOfferTypes={offerTypesFilter.setOfferTypes}
                              idSortedType={sortedTypeById.idSortedType}
                              setIdSortedType={sortedTypeById.setIdSortedType}
                              selectedColumnToOrder={sortedTypeByColumn.selectedColumnToOrder}
                              setSelectedColumnToOrder={sortedTypeByColumn.setSelectedColumnToOrder}
                              columnsToOrder={columnsToOrder}
                              columnSortedType={sortedTypeByColumn.columnSortedType}
                              setColumnSortedType={sortedTypeByColumn.setColumnSortedType}
                              runFilterHandler={runFilterHandler}
                            />
                        )}
                      </>
                    </Box>
                  </Grid>
                </MuiRecommendationsTableHeaderContainer>
                <Grid container>
                  <Grid item xs={12}>
                    {/* inicio componente */}
                    <TableContainer>
                      <Table aria-label="collapsible table">
                        <MuiTableHead>
                          <TableRow>
                            <MuiTableCell sx={{textAlign: 'left'}}>
                              <MuiCheckbox
                                checked={selectAllChecked}
                                color="secondary"
                                onChange={handleSelectAllCheckboxChange}
                              />
                            </MuiTableCell>
                            <MuiTableCell>
                              <Box sx={{  ...muiHeaderDivider, borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}` }}></Box>
                              <MuiDivHeader style={{width: 25, verticalAlign: 'middle', display: 'inline-block', transform: 'translateX(-2px)' }}>
                                {t('recommendations_list_type_header')}
                              </MuiDivHeader>
                            </MuiTableCell>
                            <MuiTableCell>
                              <Box sx={{  ...muiHeaderDivider, borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}` }}></Box>
                              <MuiDivHeader style={{width: 70, verticalAlign: 'middle', display: 'inline-block', transform: 'translateX(-7px)'}}>
                                {t('recommendations_priority_header')}
                              </MuiDivHeader>
                            </MuiTableCell>
                            <MuiTableCell align="center">
                            <Box sx={{  ...muiHeaderDivider, borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}` }}></Box>
                            <MuiDivHeader style={{width: 115, verticalAlign: 'middle', display: 'inline-block'}}>
                                {t('recommendations_pc_header')}
                              </MuiDivHeader>
                            </MuiTableCell>
                            <TableCell/>
                          </TableRow>
                        </MuiTableHead>
                        <TableBody>
                          {
                            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((recommendationItem, rowIndex) => (
                              <React.Fragment
                                key={page * rowsPerPage + rowIndex}>
                                <TableRow>
                                  <MuiTableCellItem align="center"
                                             style={{width: 69}}>
                                    <Box style={{paddingLeft: 16}}
                                         display='flex'
                                         justifyContent="space-between"
                                         alignItems="center">
                                      <MuiCheckbox
                                        color="secondary"
                                        checked={selectionModel.includes(page * rowsPerPage + rowIndex)}
                                        onChange={(event) => handleDataCheckboxChange(event, rowIndex)}
                                      />
                                      <GridActionsCellItem
                                        icon={(
                                          <MuiVisibilityIcon
                                            style={{
                                              fill: recommendationItem.id === viewSelected
                                                ? localStorage.getItem('colorPrimary')
                                                : GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[600],
                                            }}
                                          />
                                        )}
                                        onClick={() => {
                                          setViewSelected(recommendationItem.id);
                                          onSelect({
                                            lat: recommendationItem.geom.coordinates[1],
                                            lon: recommendationItem.geom.coordinates[0]
                                          });
                                        }}
                                        label='Navigate'
                                        color='primary'
                                      />
                                    </Box>
                                  </MuiTableCellItem>
                                  <MuiTableCellIcon
                                    style={{width: 69}}>
                                    {showInfoIcon(recommendationItem)}
                                  </MuiTableCellIcon>
                                  <MuiTableCellItem align="center" sx={{ margin: 'auto' }}>
                                    {recommendationItem.w_var_covered}
                                  </MuiTableCellItem>
                                  <MuiTableCellItem align="center">
                                    {recommendationItem.quant_var_covered}
                                  </MuiTableCellItem>
                                  <MuiTableCellItem>
                                    <IconButton aria-label="expand row"
                                                size="small"
                                                onClick={() => handleRowClick(rowIndex)}>
                                      {openRow === rowIndex ?
                                        <KeyboardArrowUpIcon/> :
                                        <KeyboardArrowDownIcon/>}
                                    </IconButton>
                                  </MuiTableCellItem>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                    paddingLeft: 16
                                  }} colSpan={5}>
                                    <Collapse in={openRow === rowIndex}
                                              timeout="auto" unmountOnExit>
                                      <Grid item container>
                                        <Grid item xs={12} sm={6}>
                                          <div>
                                            <MuiRecomendationsVariables variant="overline">
                                              {t('recommendations_identifier_col_data')}
                                            </MuiRecomendationsVariables>
                                            <ul style={{ marginTop: 6.5 }}>
                                              <li><Typography
                                                variant="body2">{recommendationItem.ido}</Typography>
                                              </li>
                                            </ul>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <div>
                                            <MuiRecomendationsVariables variant="overline">
                                              {t('recommendations_expanded_capacity_col_data')}
                                            </MuiRecomendationsVariables>
                                            <ul style={{ marginTop: 6.5 }}>
                                              <li><Typography
                                                variant="body2">{recommendationItem.expanded_capacity}</Typography>
                                              </li>
                                            </ul>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <div>
                                            <MuiRecomendationsVariables variant="overline">
                                              {t('recommendations_population_covered_col_data')}
                                            </MuiRecomendationsVariables>
                                            <ul style={{ marginTop: 6.5 }}>
                                              <li><Typography variant="body2">
                                                {recommendationItem.quant_var_covered}
                                              </Typography>
                                              </li>
                                            </ul>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <div>
                                            <MuiRecomendationsVariables variant="overline">
                                              {t('recommendations_expansion_cost_col_data')}
                                            </MuiRecomendationsVariables>
                                            <ul style={{ marginTop: 6.5 }}>
                                              <li><Typography
                                                variant="body2">{recommendationItem.expansion_cost}</Typography>
                                              </li>
                                            </ul>
                                          </div>
                                        </Grid>

                                      </Grid>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>

                              </React.Fragment>
                            ))
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{textAlign: 'left'}}>
                      <TablePagination
                        rowsPerPageOptions={[7]}
                        component="div"
                        count={dataRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={({
                                               from,
                                               to,
                                               count
                                             }) => `Showing ${from} to ${to} of ${count} results`}
                        sx={{
                          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                            fontSize: '12px',
                            color: '#64748B',
                            fontFamily: 'Montserrat',
                            alignItems: 'start',
                            fontWeight: 400,
                          },
                          '&.MuiTablePagination-root': {
                            display: 'flex',
                          },
                          '&.MuiTablePagination-toolbar': {
                              display: 'flex',
                              justifyContent: 'flex-start',
                          },
                        }}
                      />
                    </div>
                    {/* fin del componente */}

                  </Grid>
                </Grid>
              </MuiDivAccordion>
            </Grid>
          </form>
        </FormProvider>
      </MuiDivRoot>
    </Grid>
  );

};

export default RecommendationsWidgetUI;
